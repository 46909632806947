import React, { useState, useEffect, useCallback, useRef } from "react";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import { Link } from "gatsby";
// import axios from "axios";
import _fetch from "../../utils/_fetch";
import Checkbox from "../../Components/Checkbox";
import radio from "../../Components/Radio";
import Layout from "../../Components/Layout";
import TimeElapsed from "../../Components/TimeElapsed";
import Results from "../../Components/Results";
import Box from "../../Components/Box2";
import { ArrowToliau, ArrowAtgal } from "../../Components/Buttons";

const validationSchema = Yup.object().shape({
  answer: Yup.string().required("Laukas yra privalomas"),
})

export default () => {
  const [question, setQuestion] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [isMultiple, setIsMultiple] = useState(false)

  const getQuestion = useCallback(async questionIndex => {
    setIsLoading(true)

    _fetch(`question/${questionIndex}`, "GET", null, true).then(({ data }) => {
      if (!data.completed) {
        setCurrentQuestion(questionIndex)
        setQuestion({
          ...data,
          // choices: data.choices.map(choice => {
          //   return {
          //     ...choice,
          //     selected: false,
          //   }
          // }),
        })
      } else {
        _fetch("questionresults", "GET", null, true).then(
          ({ data: answers }) => {
            setCompleted({
              ...data,
              ...answers,
            })
          }
        )
      }

      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    getQuestion(currentQuestion + 1).then(() => {
      setIsMultiple(question?.type);
    })
  }, [currentQuestion, getQuestion, question?.type])

  const isError = () => {
    return question && question.status === "error"
  }

  const onSubmit = (values, { resetForm }) => {
    _fetch(
      `question/${currentQuestion}`,
      "POST",
      {
        questionId: question.id,
        answerId: values.answer,
      },
      true
    ).then(() => {
      resetForm({})
      getQuestion(currentQuestion + 1)
    })
  }

  //ar multiple ar ne ziuri
  const getInitialValues = () => {
    if(isMultiple)
    {
      return {
        choices: question.choices.filter(choice => choice.selected).map(choice => choice.value)
      }
    }
    else {
      const answer = question.choices.find(choice => choice.selected)
      return {
        answer: answer ? answer.value : '',
      }
    }
  }

  if (!question && !completed) {
    return null
  }

  const hasMedia = () => {
    return question && question.question && question.question.html
  }

  const timerBarIntRef = useRef();
  const timerBarIfRef = useRef();
  const timerStyleRef = useRef();

  const clearIntervals = () => {
    clearInterval(timerBarIntRef.current);
    clearInterval(timerBarIfRef.current);
    clearInterval(timerStyleRef.current);
  };

  useEffect(() => {
    let timerBar = 100;
    timerBarIntRef.current = setInterval(() => timerBar-=(100/60), 1000);
    //setInterval(() => console.log(timerBar.toFixed(3)), 1000);

    timerBarIfRef.current = setInterval(() => {
      if(timerBar <= 0)
      {
        onSubmit();
        //console.log(timerBar.toFixed(3));
        clearIntervals();
      }
    }, 1000);
    
    timerStyleRef.current = setInterval(() => document.getElementById("progressBar").style.width = timerBar.toFixed(3) + '%', 1000);

    return clearIntervals;
  })

  const renderTestQuestion = () => {
    return (
      <>
      <div className="test-page">
        <Link to="/" onClick={clearIntervals}> 
            <ArrowAtgal>GRĮŽTI Į PAGRINDINĮ PUSLAPĮ</ArrowAtgal>
        </Link>
        <Box>
        <div className="test-page--progress">
          <div className="test-page--progress-track">
            <div
              className="test-page--progress-track-filler"
              id="progressBar"
            ></div>
          </div>

          <div className="tar">
          <p id="CTA">Laikas</p>
            <div className="test-page--progress-value">
              <TimeElapsed startDate={question.started}/>
            </div>
          </div>
        </div>
        <Formik
          enableReinitialize={true}
          validationSchema={validationSchema}
          initialValues={getInitialValues()}
          onSubmit={onSubmit}
          component={({ values }) => {
            return (
              <Form>
                <div className="text-container" style={{textAlign:"left"}}>
                  <h5>{currentQuestion}.&nbsp;{question.question.text}</h5>

                  <p id="body"
                    dangerouslySetInnerHTML={{
                      __html: question.question.metrics,
                    }}
                  ></p>

                  {hasMedia() && (
                    <div className="test-page--assets-wrapper">
                      <div className="test-page--assets">
                        {question.question.html.map((item, index) => (
                          <img
                            key={`question_asset_${values.id}_${index}`}
                            src={item}
                            alt=""
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="test-page--questions">
                  {isMultiple ? (
                    values.choices
                    .map((item, index) => (
                      <Field
                        component={Checkbox}
                        required
                        defaultChecked={item.selected}
                        key={`answer_${values.id}_${index}`}
                        value={item.value}
                        name="answer"
                      >
                        <p id="body">{item.answer}</p>
                      </Field>
                    ))
                  ) : (
                    values.choices
                    .map((item, index) => (
                      <Field
                        component={radio}
                        required
                        defaultChecked={item.selected}
                        key={`answer_${values.id}_${index}`}
                        value={item.value}
                        name="answer"
                      >
                        <p id="body">{item.answer}</p>
                      </Field>
                    ))
                  )}
                </div>

                <div className="test-page--actions">
                  <ArrowToliau type="submit">
                    Kitas
                  </ArrowToliau>
                </div>
              </Form>
            )
          }}
        />
        </Box>
        </div>
      </>
    )
  }

  return (
    <Layout compact={!completed}>
      <div className="test-page">
        <Link to="/" onClick={clearIntervals}> 
            <ArrowAtgal>GRĮŽTI Į PAGRINDINĮ PUSLAPĮ</ArrowAtgal>
        </Link>
        <Box>
          {isError() && <div className="tac">{question.message}</div>}
          {!completed &&
            !isLoading &&
            question.question &&
            renderTestQuestion()}
          {completed && (
            <Results
              answers={completed.answers}
              message={completed.message}
              time={completed.results.time}
              answered={completed.results.correct}
              totalQuestions={completed.results.total}
            />
          )}
        </Box>
      </div>
    </Layout>
  )
}